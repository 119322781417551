<template>
<div class="dashboard__col">
  <h1>{{ getTitle }}</h1>
  <div class="transactions__history">
    <div class="dashboard__header">
      <h2>{{$t('dashboard.shop.title')}}</h2>
      <span>{{$t('dashboard.shop.cashback')}}: {{cashback}} {{currency}}</span>
    </div>

    <div class="search__box" v-if="false">
      <select>
        <option>{{$t('dashboard.shop.titleDesc')}}</option>
      </select>
      <input type="text" placeholder="Caută">
    </div>
    <div class="card" 
      v-for="(item, index) in orders"
      :key="index"
      :class="item.status ==  'confirmed' || item.status == 'paid'? 'purchase' : 'order'"
      :style="item.bonus == 0 ? `opacity: 0.5;`: ''"
    >
      <div class="type">
        <div class="icon" @click="openProductList(item.id)">
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="false">
            <path d="M2.76512 12H13.2375C14.6334 12 15.3346 11.306 15.3346 9.91794V2.75544C15.3346 1.36741 14.6334 0.666656 13.2375 0.666656H2.76512C1.36924 0.666656 0.667969 1.36741 0.667969 2.75544V9.91794C0.667969 11.306 1.36924 12 2.76512 12ZM1.74326 2.81608C1.74326 2.11533 2.11727 1.75148 2.78515 1.75148H13.2241C13.8853 1.75148 14.2593 2.11533 14.2593 2.81608V3.38207H1.74326V2.81608ZM2.78515 10.9152C2.11727 10.9152 1.74326 10.5581 1.74326 9.8573V4.9116H14.2593V9.8573C14.2593 10.5581 13.8853 10.9152 13.2241 10.9152H2.78515ZM3.55321 8.92072H5.18952C5.58357 8.92072 5.84405 8.65794 5.84405 8.27387V7.02734C5.84405 6.65001 5.58357 6.38049 5.18952 6.38049H3.55321C3.15916 6.38049 2.89869 6.65001 2.89869 7.02734V8.27387C2.89869 8.65794 3.15916 8.92072 3.55321 8.92072Z" fill="white"/>
          </svg>
          <img v-else src="/img/cash.svg" alt="" width="25px">
        </div>
        <span class="card__name"> <span @click="openProductList(item.id)">{{item.name}}</span>| <a :href="`tel:${item.phone}`">{{item.phone}}</a></span>
      </div>
      <div class="details">
        <div class="amount">{{ item.total_price }} {{item.currency}}</div>
        <div class="amount bonus"> + {{ item.bonus }} {{item.currency}}</div>
        <div class="time">
          <span class="date">{{item.date}}</span>
          <span>{{item.time}}</span>
        </div>
      </div>
    </div>
    <div v-if="!orders.length" style="width:100%;">
      <h3 style="width:100%; margin-top: 50px; text-align:center;">{{$t('dashboard.shop.message')}}</h3>
      <svg width="80px" height="80px" class="piggy-bank">
        <use xlink:href="/icons.svg#piggy-bank"></use>
      </svg>
    </div>
  </div>
    <div class="aq__modal__wrp" :class="{'active': modalProductList}">
    <div class="aq__modal">
      <div class="top">
        <div class="details">
          <strong>{{$t('dashboard.shop.products')}}</strong>
        </div>
        <div class="close__btn" @click="modalProductList = false">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z" fill="white"/>
          </svg>
        </div>
      </div>
      <form>
        <div v-for="item in productList.order_from_client" :key="item.id" class="product__item" @click="$router.push({name: 'shop-item', params: {id: item.product.id, lang: langStorage}})">
          <strong>Product: {{item.product.title}}</strong>
          <div class="inp__row">
            QTY: {{item.quantity}} | Price: {{item.total_price}} {{productList.currency}}
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  async created(){
    if(typeof this.timeoutID == 'number') {
      clearInterval(this.timeoutID);
    }
    await this.updatePage();
    this.timeoutID = setInterval(async () => {
      await this.updatePage()
    }, 10000);
  },
  beforeDestroy() {
    if(typeof this.timeoutID == 'number') {
      clearInterval(this.timeoutID);
    }
  },
  data(){
    return {
      orders: [],
      productList: [],
      modalProductList: false,
      cashback: 0,
      timeoutID: undefined
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      user: "user/user"
    }),
    getTitle(){  
      return this.langStorage == 'ro' ? 'Istoricul Comenzilor' : this.langStorage == 'ru' ? 'История заказов' : this.langStorage == 'es' ? 'Historial de pedidos' : 'Order History';
    },
    currency(){
      return this.user?.profile?.profile?.city == "Romania" ? "RON" : this.user?.profile?.profile?.city == "Russian Federation" ? "RUB" : "MDL";
    }
  },
  methods: {
    async getOrders(){
      try {
        const result = await fetch(`${domain}​/${this.langStorage}/shop/orders-from-client/`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        }); 
        if(!result.ok){
          throw result;
        }
        let data = await result.json();

        this.orders = data.map(e => {
          let d = new Date(e.created_at);
          let h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
          let m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
          let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
          let month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
          return {
            ...e,
            time: `${h}:${m}`,
            date: `${day}.${month}.${d.getFullYear()}`
          }
        });
      }
      catch(err){
        console.error(err)
      }

    },
    async openProductList(id){
      const data = await fetch(`${domain}/shop/orders-from-client/${id}/`, {
        headers: {
          Authorization: `Token ${this.user.token}`
        }
      }).then(async (e) => {
        if(!e.ok){
          throw e;
        }
        let responce = await e.json();
        return responce;
      }).catch(e => {
        console.error(e);
        this.$router.push({name: "Home", params: { lang: this.langStorage }});
      });
      this.productList = data;
      this.modalProductList = true;
    },
    async checkBonus(){
      const request = await fetch(`${domain}/accounts/check_bonuses/`, {
        headers: {
          Authorization: `Token ${this.user.token}`
        }
      }).then(async el => {
        if(!el.ok){
          throw el;
        }
        let responce = await el.json();
        return responce;
      }).catch(ex => {
        console.error(ex);
      });
      return request.money;
    },
    async updatePage() {
      await this.getOrders();
      this.cashback = await this.checkBonus();
    }
  }
}
</script>

<style lang="scss">
.night {
  h3 {
    color: white;
  }
}
  .dashboard__col {
    .piggy-bank {
      display: block;
      margin: auto;
      fill: #89227F;
    }
    .amount {
      &.bonus{
        color: #069348 !important;
      }
    }
    .card {
      .icon {
        cursor: pointer;
      }
      .card__name {
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          color: #930f81 !important;
          font-size: 17px !important;
        }
      }
    }
    .aq__modal__wrp {
      .product__item {
        width: 100%;
        cursor: pointer;
        transition: 0.5s;
        border-radius: 5px;
        padding: 5px;
        margin-top: 10px;
        &:hover {
          background-color: #c06bb73d;
        }
      }
      .inp__row{
        color: #9c27b0a3;
        font-weight: 500;
      }
    }
    .dashboard__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      span {
        font-size: 17px;
        font-weight: 600;
        color: #069348;
      }
    }
    @media(max-width:430px){
      .card .details .amount {
        font-size: 13px !important;
      }
    }
  }
</style>
